<template>
  <panel :title="title">
    <v-card>
      <v-toolbar
        dense
        card
      >
        <v-toolbar-title>Profile</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-expansion-panel
          popout
          focusable
        >
          <v-expansion-panel-content
            v-for="(section, i) in sections"
            :key="i"
          >
            <template #header>
              <div>{{ section.name }}</div>
            </template>
            <v-container
              v-if="i === 0"
              fluid
            >
              <v-layout
                row
                wrap
              >
                <v-flex
                  xs12
                  sm6
                >
                  <vue-avatar
                    ref="vueavatar"
                    :image="image"
                    :width="400"
                    :height="400"
                    :rotation="rotation"
                    :scale="scale"
                    @vue-avatar-editor:image-ready="onImageReady"
                  />
                </v-flex>
                <v-flex
                  xs12
                  sm6
                >
                  <v-card
                    flat
                    class="transparent"
                  >
                    <v-card-text>
                      <v-slider
                        v-model="scale"
                        :disabled="!enableUpload"
                        :label="`Scale ` + scale + `x`"
                        thumb-label="always"
                        step="0.01"
                        min="1"
                        max="3"
                      />
                    </v-card-text>
                    <v-card-text>
                      <v-slider
                        v-model="rotation"
                        :disabled="!enableUpload"
                        :label="`Rotate ` + rotation + `°`"
                        thumb-label="always"
                        step="1"
                        min="0"
                        max="360"
                      />
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer />
                      <v-btn
                        :loading="loading"
                        :disabled="!enableUpload || loading"
                        color="primary"
                        flat
                        @click="saveClicked"
                      >
                        Upload<v-icon right>
                          mdi-upload
                        </v-icon>
                        <template #loader>
                          <span class="custom-loader">
                            <v-icon light>cached</v-icon>
                          </span>
                        </template>
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-container>
            <template v-else>
              <v-card v-if="section.disclaimer">
                <v-alert
                  :value="true"
                  type="info"
                >
                  <div v-html="section.disclaimer" />
                </v-alert>
              </v-card>
              <v-data-table
                :items="section.items"
                hide-actions
                hide-headers
              >
                <template #items="props">
                  <td class="text-xs-left">
                    {{ props.item.name }}
                  </td>
                  <td
                    v-if="!props.item.component"
                    class="text-xs-left"
                  >
                    {{ props.item.value }}
                  </td>
                  <td
                    v-else-if="props.item.component === 'text' && props.item.key === 'accountNric' "
                    class="text-xs-left"
                  >
                    <v-text-field
                      v-model="props.item.newValue"
                      v-bind="props.item.props ? props.item.props : {}"
                      hint="Please make sure no space or dash(-) for NRIC"
                    />
                  </td>
                  <td
                    v-else-if="props.item.component === 'text' && props.item.key !== 'newRoc'"
                    class="text-xs-left"
                  >
                    <v-text-field
                      v-model="props.item.newValue"
                      v-bind="props.item.props ? props.item.props : {}"
                    />
                  </td>
                  <td
                    v-else-if="props.item.key === 'newRoc'"
                    class="text-xs-left"
                  >
                    <v-text-field
                      v-model="props.item.newValue"
                      v-bind="props.item.props ? props.item.props : {}"
                      hint="e.g.:202003111122 -(12 digits)"
                    />
                  </td>

                  <td
                    v-else-if="props.item.component === 'business' "
                    class="text-xs-left"
                  >
                    <v-text-field
                      v-model="props.item.newValue"
                      v-bind="props.item.props ? props.item.props : {}"
                      hint="Insert principal activity as per your company`s audited report /describe your business activity."
                    />
                  </td>
                  <td
                    v-else-if="props.item.component === 'msic' "
                    class="text-xs-left"
                  >
                    <v-text-field
                      v-model="props.item.newValue"
                      v-bind="props.item.props ? props.item.props : {}"
                      hint="Please refer to info  above"
                    />
                  </td>
                  <td
                    v-else-if="props.item.component === 'date'"
                    class="text-xs-left"
                  >
                    <v-menu
                      v-model="props.item.menu"
                      :close-on-content-click="false"
                      lazy
                      transition="scale-transition"
                      offset-y
                    >
                      <template #activator="{ on }">
                        <v-text-field
                          v-model="props.item.newValue"
                          readonly
                          v-on="on"
                        />
                      </template>
                      <v-date-picker

                        v-model="props.item.newValue"
                        no-title
                        @input="props.item.menu = false"
                      />
                    </v-menu>
                  </td>
                  <td
                    v-else-if="props.item.component === 'select'"
                    class="text-xs-left"
                  >
                    <v-select
                      v-model="props.item.newValue"
                      v-bind="props.item.bind ? props.item.bind : {}"
                      :items="props.item.selections"
                      item-text="name"
                      item-value="key"
                    />
                  </td>
                  <td
                    v-if="props.item.component"
                    class="justify-center layout"
                  >
                    <v-tooltip bottom>
                      <template #activator="{ on }">
                        <v-btn
                          :disabled="props.item.value === props.item.newValue"
                          color="success"
                          flat
                          icon
                          v-on="on"
                          @click="saveItem(props.item)"
                        >
                          <v-icon>mdi-check</v-icon>
                        </v-btn>
                      </template>
                      <span>Update</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template #activator="{ on }">
                        <v-btn
                          :disabled="props.item.value === props.item.newValue"
                          color="error"
                          flat
                          icon
                          v-on="on"
                          @click="props.item.newValue = props.item.value"
                        >
                          <v-icon>mdi-undo-variant</v-icon>
                        </v-btn>
                      </template>
                      <span>Reset</span>
                    </v-tooltip>
                  </td>
                  <td v-else>
                    &nbsp;
                  </td>
                </template>
              </v-data-table>
            </template>
          </v-expansion-panel-content>
          <v-expansion-panel-content>
            <template #header>
              <div>Change Password</div>
            </template>
            <v-btn
              v-if="idcs"
              color="primary"
              flat
              href="http://xoxid.xox.com.my/site/reset"
            >
              Change Password Here
            </v-btn>
            <v-card v-else>
              <v-alert
                v-if="!alert"
                :value="true"
                color="info"
                icon="info"
              >
                Passwords must contain at least 8 characters, including uppercase, lowercase letters, numbers and special characters.
              </v-alert>
              <v-alert
                :value="alert"
                type="error"
                transition="scale-transition"
              >
                {{ alertMessage }}
              </v-alert>
              <v-card-text>
                <v-form
                  ref="passwordForm"
                  v-model="validPassword"
                  lazy-validation
                >
                  <v-container grid-list-xs>
                    <v-layout
                      row
                      wrap
                    >
                      <v-flex xs12>
                        <v-text-field
                          v-model="currentPassword"
                          :append-icon="show0 ? 'visibility' : 'visibility_off'"
                          :type="show0 ? 'text' : 'password'"
                          label="Enter current password"
                          counter
                          required
                          @click:append="show0 = !show0"
                        />
                        <v-text-field
                          v-model="password1"
                          :append-icon="show1 ? 'visibility' : 'visibility_off'"
                          :rules="passwordRules"
                          :type="show1 ? 'text' : 'password'"
                          label="Enter new password"
                          hint="At least 8 characters including uppercase, lowercase letters, numbers and special characters"
                          counter
                          required
                          lazy-validation
                          @click:append="show1 = !show1"
                        />
                      </v-flex>
                      <v-flex xs12>
                        <v-text-field
                          v-model="password2"
                          :append-icon="show2 ? 'visibility' : 'visibility_off'"
                          :rules="passwordMatchRules"
                          :type="show2 ? 'text' : 'password'"
                          label="Re-enter new password"
                          counter
                          required
                          @click:append="show2 = !show2"
                        />
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  :disabled="!validPassword"
                  color="primary"
                  flat
                  @click="changePassword()"
                >
                  Change Password
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-card-text>
    </v-card>
    <v-dialog
      v-if="showJsonPayload"
      v-model="showJsonPayload"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      persistent
    >
      <v-card>
        <v-card-title class="headline">
          Submission Payload
        </v-card-title>
        <v-card-text>
          <div v-highlight>
            <pre class="language-javascript">
              <code>
              {{ submissionResponse }}
              </code>
            </pre>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            flat
            @click.native="showJsonPayload = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <template v-if="dealerxox">
      <v-dialog
        v-model="consentDialog"
        persistent
        height="300"
        width="800"
      >
        <v-card>
          <v-card-title
            class="black"
            primary-title
          >
            Consent for 3rd Party
          </v-card-title>
          <v-card-text>
            <v-alert
              :value="true"
              type="info"
            >
              Please click the button to download<v-btn
                href="http://onesys.onexox.my/form/consent_3rd_party.pdf"
                target="_blank"
                color="blue darken-4"
                small
              >
                <v-icon
                  small
                >
                  mdi-download
                </v-icon>
                Consent Letter
              </v-btn> and upload it with references.
              <br>
              Please take note the file upload only support 1 file, up to 2MB.
            </v-alert>
            <br>
            Attach Consent Letter:
            <upload3rdParty
              :title="id1"
              @closeDialog="close"
              @opensnackbar2="opensnackbar2"
            />
            <v-divider />
            <br>
            Attach Third Party Idenification Card:
            <upload3rdParty
              :title="id2"
              @closeDialog="close"
              @opensnackbar2="opensnackbar2"
            />
            <v-divider />
            <br>
            Attach Witness's identification Card:
            <upload3rdParty
              :title="id3"
              @closeDialog="close"
              @opensnackbar2="opensnackbar2"
            />
            <v-divider />
            <br>
            Attach Letter of authorization:
            <upload3rdParty
              :title="id4"
              @closeDialog="close"
              @opensnackbar2="opensnackbar2"
            />
            <v-divider />
            <br>
            Attach Form 49,51,58:
            <upload3rdParty
              :title="id5"
              @closeDialog="close"
              @opensnackbar2="opensnackbar2"
            />
            <v-divider />
            <br>
            Attach SSM Document:
            <upload3rdParty
              :title="id6"
              @closeDialog="close"
              @opensnackbar2="opensnackbar2"
            />
          </v-card-text>
        </v-card>
      </v-dialog>
    </template>
    <v-snackbar
      v-model="snackbar"
      :color="snackbarStatus"
      top
    >
      {{ snackbarText }}
    </v-snackbar>
    <v-snackbar
      v-model="snackbar2"
      :color="snackbarStatus2"
      top
    >
      {{ snackbarText2 }}
    </v-snackbar>
    <v-snackbar
      v-model="snackbar3"
      :color="snackbarStatus2"
      top
    >
      {{ snackbarText3 }}
    </v-snackbar>
    <template v-if="dealerxox">
      <v-dialog
        v-model="ssmDialog"
        persistent
        max-width="600px"
      >
        <ssmDocumentCP58
          @closeDialog="close"
          @opensnackbar="opensnackbar"
        />
      </v-dialog>
    </template>
    <template v-if="!dealerxox">
      <v-dialog
        v-model="incentiveDialog"
        width="500"
      >
        <v-card>
          <v-card-title>
            Reminder
          </v-card-title>

          <v-alert
            :value="true"
            type="info"
          >
            Please ensure to update the NRIC to ROC No in the Incentive Payment tab when you change the account number from Personal to Company.
          </v-alert>

          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              flat
              @click="incentiveDialog = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </panel>
</template>

<script>
import security from '@/security'
import 'vue-code-highlight/themes/prism-twilight.css'
import { createGetParams, createPostParams } from '@/rest'
import { VueAvatar } from 'vue-avatar-editor-improved'

const pageTitle = 'Account'

export default {
  components: {
    Panel: () => import(/* webpackChunkName: "panel" */ '@/components/Panel'),
    // NricImage3rdPartyUpload: () => import(/* webpackChunkName: "nricImage3rdPartyUpload" */ '@/components/NricImage3rdPartyUpload'),
    // ConsentLetterUploadField: () => import(/* webpackChunkName: "consentletteruploadfield" */ '@/components/ConsentLetterUploadField'),
    // NricWitnessUpload: () => import(/* webpackChunkName: "nricWitnessupload" */ '@/components/NricWitnessUpload'),
    // LetterAuthorizationUpload: () => import(/* webpackChunkName: "letterAuthorizationUpload" */ '@/components/letterAuthorizationUpload'),
    // Ssm3rdPartyUpload: () => import(/* webpackChunkName: "ssm3rdPartyUpload" */ '@/components/Ssm3rdPartyUpload'),
    // forSdnBhdUpload: () => import(/* webpackChunkName: "forSdnBhdUpload" */ '@/components/forSdnBhdUpload'),
    ssmDocumentCP58: () => import(/* webpackChunkName: "ssmDocumentCP58" */ '@/components/ssmDocumentCP58'),
    upload3rdParty: () => import(/* webpackChunkName: "upload3rdParty" */ '@/components/upload3rdParty'),
    VueAvatar,
  },
  data () {
    return {
      id1: 'consentLetter',
      id2: '3rdPartyIc',
      id3: 'witnessIc',
      id4: 'letterAuthorization',
      id5: 'form59',
      id6: 'ssm',
      alert: false,
      alertMessage: null,
      currentPassword: null,
      enableUpload: false,
      image: null,
      loading: false,
      password1: null,
      password2: null,
      passwordRules: [
        v => (v || '').length >= 8 || 'Minimum 8 characters!',
        v => /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/.test(v) || 'Invalid password!',
      ],
      ssmStatus: [],
      status: null,
      rotation: 0,
      scale: 1,
      show0: false,
      show1: false,
      show2: false,
      snackbar: false,
      snackbarText: null,
      snackbarStatus: 'success',
      submissionResponse: null,
      showJsonPayload: false,
      maxWidth: '600px',
      snackbar2: false,
      snackbar3: false,
      snackbarText2: 'SSM Document updated.',
      snackbarText3: '3rd Party Document updated',
      snackbarStatus2: 'success',
      incentiveDialog: false,
      ssmDialog: false,
      consentDialog: false,
      xox: true,
      sections: [
        {
          name: 'Avatar',
        },
        {
          name: 'Subscription',
          items: [
            { key: 'msisdn', name: 'Msisdn', component: null, value: null, newValue: null },
            { key: 'activation', name: 'Activation date', component: null, value: null, newValue: null },
            { key: 'introducer', name: 'Introducer', component: null, value: null, newValue: null },
            { key: 'ugu', name: 'UGU', component: null, value: null, newValue: null },
          ],
        },
        {
          name: 'Personal Details',
          items: [
            { key: 'name', name: 'Name', component: null, value: null, newValue: null },
            { key: 'nric', name: 'NRIC', component: null, value: null, newValue: null },
            // { key: 'contact', name: 'Contact number', component: null, value: null, newValue: null },
            { key: 'address', name: 'Address', component: 'text', value: null, newValue: null },
            { key: 'city', name: 'City', component: 'text', value: null, newValue: null },
            { key: 'postalCode', name: 'Postal code', component: 'text', value: null, newValue: null },
            { key: 'state', name: 'State', component: 'select', selections: [], value: null, newValue: null },
            { key: 'district', name: 'District', component: 'text', value: null, newValue: null },
            { key: 'gender', name: 'Gender', component: 'select', selections: [], value: null, newValue: null },
            { key: 'dob', name: 'Date of Birth', component: 'date', value: null, newValue: null, menu: false },
            { key: 'maritalStatus', name: 'Marital Status', component: 'select', selections: [], value: null, newValue: null },
            { key: 'race', name: 'Race', component: 'select', value: null, selections: [], newValue: null },
            { key: 'educationLevel', name: 'Education Level', component: 'select', selections: [], value: null, newValue: null },
            { key: 'profession', name: 'Profession', component: 'select', value: 'select', selections: [], newValue: null },
            { key: 'householdIncome', name: 'Monthly Household Income', component: 'select', selections: [], value: null, newValue: null },
            { key: 'contact', name: 'Contact Number', component: 'text', value: null, newValue: null },
            { key: 'contact2', name: 'Second Contact Number', component: 'text', value: null, newValue: null },
            { key: 'email', name: 'Email', component: 'text', value: null, newValue: null },
          ],
        },
        {
          name: 'Dealership',
          items: [
            { key: 'dealerId', name: 'Dealer Id', component: null, value: null, newValue: null },
            { key: 'rank', name: 'Rank', component: null, value: null, newValue: null },
            { key: 'dealershipDate', name: 'Dealership Date', component: null, value: null, newValue: null },
            { key: 'workingType', name: 'Working Type', component: 'select', selections: [], value: null, newValue: null },
            { key: 'roc', name: 'ROC No.', component: 'text', value: null, newValue: null },
            { key: 'newRoc', name: 'New ROC No.', component: 'text', value: null, newValue: null },
            { key: 'rocName', name: 'Company Name', component: 'text', value: null, newValue: null },
            { key: 'companyType', name: 'Company Type', component: 'select', selections: [], value: null, newValue: null },
            { key: 'tax', name: 'Tax Identification No (TIN)', component: 'text', value: null, newValue: null },
            { key: 'sst', name: 'SST No', component: 'text', value: null, newValue: null },
            { key: 'tourismTax', name: 'Tourism Tax Registration No.', component: 'text', value: null, newValue: null },
            { key: 'msicCode', name: 'MSIC Code', component: 'msic', value: null, newValue: null },
            { key: 'businessActivity', name: 'Business Activity', component: 'business', value: null, newValue: null },
          ],
          disclaimer:
            '<p>For <span class="font-weight-bold">MSIC Code </span>field please refer Company Income Tax Return Form (Form-C) or refer to  ' + '<button class="btn"><a href="http://lampiran1.hasil.gov.my/pdf/pdfam/NewBusinessCodes_MSIC2008_2.pdf">  Click here </a></button>' +
            '  and choose the MSIC code that align with your business activity.</p>',
        },
        {
          name: 'Incentive Payment',
          items: [
            {
              key: 'paymentMethod',
              name: 'Payment method',
              component: 'select',
              bind: {
                hint: null,
                persistentHint: true,
              },
              selections: [
                { key: 'cash', name: 'Cash' },
                { key: 'erecharge', name: 'eRecharge' },
              ],
              value: null,
              newValue: null,
            },
            {
              key: 'bankName',
              name: 'Bank name',
              component: 'select',
              selections: [],
              value: null,
              newValue: null,
            },
            { key: 'accountHolder', name: 'Account holder', component: 'text', value: null, newValue: null },
            { key: 'accountNumber', name: 'Account number', component: 'text', value: null, newValue: null, props: { mask: '####################' } },
            { key: 'accountNric', name: 'NRIC / ROC No', component: 'text', value: null, newValue: null },
            { key: 'account3rdParty', name: 'NRIC 3rd Party', component: 'text', value: null, newValue: null },
          ],
          disclaimer: '<p>Incentive disbursement is carried out on day 15 of the month. ' +
            'If the date falls during the weekend or public holiday the disbursement will be carried out on the next working day.</p>' +
            '<p>Payment method is nullified at the begining of each month. It is the sole responsibility of the dealer to ' +
            'ensure that a desired choice is selected before the end of each month. E-recharge method is taken as the default ' +
            'should there be no selection.</p>',
        },
        {
          name: 'Beneficiary',
          items: [
            { key: 'beneficiaryName', name: 'Name', component: 'text', value: null, newValue: null },
            { key: 'beneficiaryNric', name: 'NRIC', component: 'text', value: null, newValue: null },
            { key: 'beneficiaryRelationship', name: 'Relationship', component: 'text', value: null, newValue: null },
            { key: 'beneficiaryContact', name: 'Contact number', component: 'text', value: null, newValue: null, props: { mask: '###########' } },
          ],
          disclaimer: '<h3>Beneficiary upon the death of a dealer.</h3><br>' +
            '<p><span class="font-weight-bold">Option 1:</span> If the beneficiary does not intent to carry on with the duties of the deceased dealer the beneficiary ' +
            'can rightfully claim the incentive from OneXOX and is responsible to distribute the incentive to the heirs in ' +
            '<span class="font-weight-bold">Faraid</span> terms.</p>' +
            '<p><span class="font-weight-bold">Option 2:</span> If the beneficiary intents to take over and carry out duties as a dealer this business endeavor shall ' +
            'be shared between the heirs. However, the company will only be in liaison with the named beneficiary.</p>',
        },
      ],
      title: pageTitle,
      validPassword: true,
    }
  },
  computed: {
    passwordMatchRules () {
      return [v => (!!v && v) === this.password1 || 'Values do not match']
    },
    idcs () {
      return security.me.username().indexOf('@') > -1
    },
    dealerxox: function () {
      return security.me.claimer() === 'xox' ? this.xox : !this.xox
    },
    items: function () {
      return this.field.props.items
    },

  },
  mounted () {
    const params = createGetParams({
      profiling: true,
    })

    this.$rest.get('getProfile.php', params)
      .then(response => {
        const data = response.data
        let index = 1

        data.msisdn && this.assignValue(this.sections[index].items, 'msisdn', data.msisdn)
        data.line_activation && this.assignValue(this.sections[index].items, 'activation', data.line_activation)
        data.introducer && this.assignValue(this.sections[index].items, 'introducer', data.introducer)
        data.ugu && this.assignValue(this.sections[index].items, 'ugu', data.ugu)

        index++
        data.name && this.assignValue(this.sections[index].items, 'name', data.name)
        data.id && this.assignValue(this.sections[index].items, 'nric', data.id)
        data.contact && this.assignValue(this.sections[index].items, 'contact', data.contact)
        data.street && this.assignValue(this.sections[index].items, 'address', data.street)
        data.city && this.assignValue(this.sections[index].items, 'city', data.city)
        data.postal_code && this.assignValue(this.sections[index].items, 'postalCode', data.postal_code)
        data.state && this.assignValue(this.sections[index].items, 'state', data.state)
        data.district && this.assignValue(this.sections[index].items, 'district', data.district)
        data.gender && this.assignValue(this.sections[index].items, 'gender', data.gender)
        data.dob && this.assignValue(this.sections[index].items, 'dob', data.dob)
        data.maritalStatus && this.assignValue(this.sections[index].items, 'maritalStatus', data.maritalStatus)
        data.race && this.assignValue(this.sections[index].items, 'race', data.race)
        data.educationLevel && this.assignValue(this.sections[index].items, 'educationLevel', data.educationLevel)
        data.profession && this.assignValue(this.sections[index].items, 'profession', data.profession)
        data.householdIncome && this.assignValue(this.sections[index].items, 'householdIncome', data.householdIncome)
        data.contact && this.assignValue(this.sections[index].items, 'contact', data.contact)
        data.contact2 && this.assignValue(this.sections[index].items, 'contact2', data.contact2)
        data.email && this.assignValue(this.sections[index].items, 'email', data.email)

        index++
        data.dealer && this.assignValue(this.sections[index].items, 'dealerId', data.dealer)
        data.rank && this.assignValue(this.sections[index].items, 'rank', data.rank)
        data.dealer_activation && this.assignValue(this.sections[index].items, 'dealershipDate', data.dealer_activation)
        data.workingType && this.assignValue(this.sections[index].items, 'workingType', data.workingType)
        data.roc && this.assignValue(this.sections[index].items, 'roc', data.roc)
        data.newRoc && this.assignValue(this.sections[index].items, 'newRoc', data.newRoc)
        data.rocName && this.assignValue(this.sections[index].items, 'rocName', data.rocName)
        data.companyType && this.assignValue(this.sections[index].items, 'companyType', data.companyType)
        data.tax && this.assignValue(this.sections[index].items, 'tax', data.tax)
        data.sst && this.assignValue(this.sections[index].items, 'sst', data.sst)
        data.tourismTax && this.assignValue(this.sections[index].items, 'tourismTax', data.tourismTax)
        data.msicCode && this.assignValue(this.sections[index].items, 'msicCode', data.msicCode)
        data.businessActivity && this.assignValue(this.sections[index].items, 'businessActivity', data.businessActivity)

        index++
        data.payment_method && this.assignValue(this.sections[index].items, 'paymentMethod', data.payment_method,
          'Update on ' + data.payment_method_entry)
        data.bank_name && this.assignValue(this.sections[index].items, 'bankName', data.bank_name)
        data.account_holder && this.assignValue(this.sections[index].items, 'accountHolder', data.account_holder)
        data.account_number && this.assignValue(this.sections[index].items, 'accountNumber', data.account_number)
        data.account_nric && this.assignValue(this.sections[index].items, 'accountNric', data.account_nric)
        data.account_3rdparty && this.assignValue(this.sections[index].items, 'account3rdParty', data.account_3rdparty)

        index++
        data.beneficiary_name && this.assignValue(this.sections[index].items, 'beneficiaryName', data.beneficiary_name)
        data.beneficiary_nric && this.assignValue(this.sections[index].items, 'beneficiaryNric', data.beneficiary_nric)
        data.beneficiary_relationship && this.assignValue(this.sections[index].items, 'beneficiaryRelationship', data.beneficiary_relationship)
        data.beneficiary_contact && this.assignValue(this.sections[index].items, 'beneficiaryContact', data.beneficiary_contact)
      }).catch(e => {
        // ignore
      })

    this.$rest.get('getStates.php', {})
      .then(response => {
        response.data.forEach(element => {
          this.sections[2].items[5].selections.push({
            key: element.code,
            name: element.state,
          })
        })
      }).catch(e => {
        // ignore
      })

    this.$rest.get('getSelectionItems.php', createGetParams({ kind: 'gender' }))
      .then(response => {
        this.sections[2].items[7].selections = response.data
      }).catch(e => {
        // ignore
      })
    this.$rest.get('getSelectionItems.php', createGetParams({ kind: 'gender' }))
      .then(response => {
        this.sections[2].items[7].selections = response.data
      }).catch(e => {
        // ignore
      })
    this.$rest.get('getSelectionItems.php', createGetParams({ kind: 'maritalStatus' }))
      .then(response => {
        this.sections[2].items[9].selections = response.data
      }).catch(e => {
        // ignore
      })
    this.$rest.get('getSelectionItems.php', createGetParams({ kind: 'race' }))
      .then(response => {
        this.sections[2].items[10].selections = response.data
      }).catch(e => {
        // ignore
      })
    this.$rest.get('getSelectionItems.php', createGetParams({ kind: 'educationLevel' }))
      .then(response => {
        this.sections[2].items[11].selections = response.data
      }).catch(e => {
        // ignore
      })
    this.$rest.get('getSelectionItems.php', createGetParams({ kind: 'profession' }))
      .then(response => {
        this.sections[2].items[12].selections = response.data
      }).catch(e => {
        // ignore
      })
    this.$rest.get('getSelectionItems.php', createGetParams({ kind: 'householdIncome' }))
      .then(response => {
        this.sections[2].items[13].selections = response.data
      }).catch(e => {
        // ignore
      })
    this.$rest.get('getSelectionItems.php', createGetParams({ kind: 'workingType' }))
      .then(response => {
        this.sections[3].items[3].selections = response.data
      }).catch(e => {
        // ignore
      })
    this.$rest.get('getSelectionItems.php', createGetParams({ kind: 'companyType' }))
      .then(response => {
        this.sections[3].items[7].selections = response.data
      }).catch(e => {
        // ignore
      })
    this.$rest.get('getBanks.php', {})
      .then(response => {
        this.sections[4].items[1].selections = response.data
      }).catch(e => {
        // ignore
      })
    this.$rest.get('getAvatar.php', createGetParams({}))
      .then(response => {
        this.$refs.vueavatar[0].loadImage(response.data.avatar)
      }).catch(e => {
        // ignore
      })
  },
  methods: {
    /*
    getStateByCode (key) {
      this.sections[2].items[5].selections.find(element => element.key === key)
    },
    */

    saveClicked () {
      this.loading = true
      this.$nextTick(() => {
        var img = this.$refs.vueavatar[0].getImageScaled()
        this.saveItem({ key: 'avatar', newValue: img.toDataURL() })
      })
    },

    onImageReady () {
      this.enableUpload = true
      this.scale = 1
      this.rotation = 0
    },
    async saveItem (item) {
      const params = createPostParams({
        action: 'updateProfileItems',
        payload: {
          key: item.key,
          value: item.newValue,
        },
      })

      try {
        const response = await this.$rest.post('postAction.php', params)
        this.$nextTick(() => {
          item.value = item.newValue
          this.ssmDialog = response.data.ssmDialog
          this.consentDialog = response.data.consentDialog
          this.status = response.data.status
          if (this.$store.state.security.impersonate) {
            this.showSubmissionResponse(response.data)
          } else if (item.key === 'accountNumber') {
            this.incentiveDialog = true
            this.snackbarText = response.data.result
            this.snackbarStatus = 'success'
            this.snackbar = true
          } else if (this.ssmDialog === true) {
            this.ssmDialog = true
          } else if (this.consentDialog === true) {
            this.consentDialog = true
          } else if (this.ssmDialog === false && item.key === 'companyType') {
            if (this.status === 'pending') {
              this.ssmDialog = false
              this.snackbarText = response.data.result
              this.snackbarStatus = 'warning'
              this.snackbar = true
            } else if (this.status === 'success') {
              this.ssmDialog = false
              this.snackbarText = response.data.result
              this.snackbarStatus = 'success'
              this.snackbar = true
            } else {
              this.consentDialog = false
              this.ssmDialog = false
              this.snackbarText = response.data.result
              this.snackbarStatus = 'success'
              this.snackbar = true
            }
          } else {
            this.snackbarText = response.data.result
            if (item.bind) {
              item.bind.hint = response.data.update
            }
            this.snackbarStatus = 'success'
            this.snackbar = true
          }
          this.loading = false
        })
      } catch (error) {
        this.$nextTick(() => {
          this.snackbarText = error.message + ': ' + error.response.data
          this.snackbarStatus = 'error'
          this.snackbar = true
        })
      }
    },
    assignValue: function (model, key, value, remark) {
      const item = model.find(function (element) {
        return element.key === key
      })
      if (item) {
        item.value = item.newValue = value
        if (remark) {
          item.bind.hint = remark
        }
      }
    },
    async changePassword () {
      this.alert = false
      if (!this.$refs.passwordForm.validate()) return
      try {
        const params = createPostParams({
          action: 'changePassword',
          payload: {
            currentPassword: this.currentPassword ? this.currentPassword : '*',
            password: this.password2,
          },
        })
        const response = await this.$rest.post('postAction.php', params)
        this.snackbarText = response.data.result
        this.snackbarStatus = 'success'
        this.snackbar = true
      } catch (error) {
        let message = error.message
        if (error.response) {
          message += ': ' + error.response.data
        }
        this.showError(message)
      }
    },
    showError (message) {
      this.alertMessage = message
      this.alert = true
    },
    showSubmissionResponse: function (response) {
      this.submissionResponse = response
      this.showJsonPayload = true
    },
    close () {
      this.ssmDialog = false
      this.consentDialog = false
    },

    opensnackbar () {
      this.snackbar2 = true
    },
    opensnackbar2 () {
      this.snackbar3 = true
    },
  },
}
</script>

<style>
a{
text-decoration:none;
color:white;
}
.btn{
background-color:black;}
.btn:hover {background: gray;}
.btn:hover > a {color:black}
  .custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }
  @-moz-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>
